import React from "react";
import {Link} from "gatsby"

function Footer() {
    return (
        <footer className="bg-black text-white w-full px-4 xl:px-0 flex flex-col py-8 items-center justify-center">
            <p className="text-sm font-thin">
                &copy; QuickComp
            </p>
            <div className="flex justify-evenly text-xs font-thin uppercase w-64 mt-4">
                <Link to="/terms">Terms of Service</Link>
                <Link to="/privacy">Privacy Policy</Link>
            </div>
        </footer>
    );
}

export default Footer;
