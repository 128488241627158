import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import Hero from "../components/hero";
import Pricing from "../components/pricing";
import Closer from "../components/closer";
import Footer from "../components/footer";
import Contact from "../components/contact";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`quickcomp`, `comp`, `appraisal`]}
        title="QuickComp"
      />
      <Header/>
      <div className="w-full">
          <Hero />
          <Pricing/>
          <Closer/>
          <Contact/>
      </div>
      <Footer/>

      {/*<Checkout/>*/}
    </Layout>
  );
}

export default IndexPage;
