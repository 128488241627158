import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {H0} from "./typography";
import Button from "./button";

import Chevron from "../images/chevron.svg";


export default function Closer() {
    const { screen } = useStaticQuery(graphql`
        query CloserQuery {
          screen: file(relativePath: {eq: "screen.png"}) {
             image: childImageSharp {
              fluid(maxWidth: 728) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `);
    return (
        <section className="lg:min-h-screen flex flex-col justify-center items-center my-8 lg:my-0 pb-8 lg:pb-0">
            <div className="bg-primary bg-cover w-full pt-4 md:pt-0" style={{
                backgroundImage: "url('/constellation.svg')",
                backgroundBlendMode: "soft-light"
            }}>
                <div className="max-w-6xl mx-auto px-4 xl:px-0 flex flex-wrap items-center ">
                    <div className="w-full md:w-1/2">
                        <H0 className="leading-tight text-center md:text-left">Work smarter, not harder.</H0>
                        <p className="font-thin text-xl my-8">
                            Get a <b>free</b> account now, no credit card needed.
                        </p>
                        <div className="text-center md:text-left">
                            <Button invert={true} href="https://app.quickcomp.io/signup">
                                Get Started <Chevron className="ml-2 h-4"/>
                            </Button>
                        </div>

                    </div>
                    <div className="w-full md:w-1/2">
                        <Img fluid={screen.image.fluid} className="-bottom-8 shadow-xl" />
                    </div>
                </div>

            </div>
        </section>
    )
}
