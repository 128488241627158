import React, {useState} from "react";
import {Element} from "react-scroll";
import PropTypes from "prop-types";
import Recaptcha from "react-recaptcha";

import {H1, P} from "./typography";
import Button from "./button";


function Label({children, ...props}) {
    return (
        <label className="block text-black text-sm font-light mb-2" {...props}>
            {children}
        </label>
    );
}
Label.propTypes = {
    children: PropTypes.node
}

function TextField({type, ...props}) {
    return (
        <input
            className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type={type} {...props} />
    )
}
TextField.propTypes = {
    type: PropTypes.string
}
TextField.defaultValues = {
    type: "text"
}

function TextArea({...props}) {
    return (
        <textarea
            className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            {...props} />
    )
}
export default function Contact() {
    const [isSending, setSending] = useState(false);
    const [isSent, setSent] = useState(false);
    const [isError, setError] = useState(false);
    const [form, setForm] = useState({
        name: "",
        org: "",
        email: "",
        body: ""
    });
    const [token, setToken] = useState("");

    const send = () => {
        setSending(true);
        const body = JSON.stringify({ ...form, token});
        fetch("https://us-central1-mastercomp-c3960.cloudfunctions.net/contact", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body
        })
            .then(rsp => rsp.json())
            .then(data => {
                console.log(data);
                setSent(true);
            })
            .catch(error => {
                console.log(error);
                setSent(true);
                setError(true);
            });
    };

    const onLoad = () => {
        console.log('Recaptcha loaded');
    }

    const onVerify = rsp => {
        setToken(rsp);
    }

    const onChange = evt => {
        setForm({...form, [evt.target.name]: evt.target.value});
    }

    const submit = evt => {
        evt.preventDefault();
    }

    const isValid = form.name.trim() && form.org.trim() && form.email.trim() && form.body.trim() && token.trim();
    console.log(isValid);
    return (
        <Element name="contact">
            <section className="lg:min-h-screen max-w-5xl mx-auto flex flex-col justify-center items-center my-8 lg:my-0 pb-8 lg:pb-0">
                <H1 className="text-center lg:text-left">{"Have questions or looking for a demo? Drop us a line."}</H1>
                <p className="font-hairline text-base md:text-lg lg:text-xl text-center lg:text-left mt-4">{"We'd love to hear from you! Use the form below to ask any questions or let us know if you want to see more and we'll happily schedule a demo for you."}</p>
                <form className="px-8 pt-6 pb-8 mt-4 w-full" onSubmit={submit}>
                    <div className="mb-4">
                        <Label htmlFor="name">Name</Label>
                        <TextField id="name" name="name" placeholder="Your name"
                                   value={form.name} onChange={onChange}/>
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="org">Organization</Label>
                        <TextField id="org" name="org" placeholder="Your organization"
                                   value={form.org} onChange={onChange}/>
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="email">Email</Label>
                        <TextField id="email" name="email" placeholder="Your email address"
                                   value={form.email} onChange={onChange}/>
                    </div>
                    <div className="mb-4">
                        <TextArea id="body" name="body" rows={10}
                            value={form.body} onChange={onChange}/>
                    </div>
                    {!isSent && <div className="mb-4">
                        <Recaptcha
                            sitekey="6LeC4-EZAAAAAPLncjRxSF4PKy6MyGPOwO26inCq"
                            render="explicit"
                            onloadCallback={onLoad}
                            verifyCallback={onVerify}
                        />
                    </div>}
                    <div className="flex items-center justify-between">
                        {isSent && !isError && <P>Thanks! We will be in touch soon.</P>}
                        {isSent && isError && <P>Oops, something went wrong. Feel free to email us at <a href="mailto:hello@quickcomp.io">hello@quickcomp.io</a></P>}
                        {!isSent && <Button disabled={!isValid || isSending} onClick={(!isValid||isSending)?null:send}>{isSending?"Working..." : "Send"}</Button>}
                    </div>
                </form>
            </section>
        </Element>
    )
}
