import React from "react";
import {scroller} from "react-scroll";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import Button from "./button";
import {H1} from "./typography";
import Chevron from "../images/chevron.svg";

export default function Hero() {
    const { hero } = useStaticQuery(graphql`
        query HeroQuery {
          hero: file(relativePath: {eq: "hero.jpg"}) {
             image: childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          pattern: file(relativePath: {eq: "constellation-g.svg"}) {
             url: publicURL
          }
        }
      `);
    const handleSignUp = () => {
        scroller.scrollTo('pricing', {
            duration: 750,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    };
    const handleContactUs = () => {
        scroller.scrollTo('contact', {
            duration: 750,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    };
    return (
        <div className="flex flex-wrap items-center justify-center lg:min-h-screen max-w-6xl mx-auto px-4 xl:px-0 pt-8 lg:pt-0">
            <div className="w-full lg:w-1/2">
                <H1 className="text-center lg:text-left leading-tight">Quick and easy comparables for commercial appraisers.</H1>
                <p className="font-hairline text-base md:text-lg lg:text-xl mt-8">We are a cloud-based platform for appraisers to manage comparables with streamlined data entry, a searchable map interface, and customizable reports. Easily enter, find, and export IC&I, Multi-Family, Vacant Land, Lease, and Residential Rent comps.</p>
                <div className="mt-8 text-center lg:text-left">
                    <Button onClick={handleSignUp}>
                        Get Started <Chevron className="ml-2 h-4"/>
                    </Button>
                    <a className="hover:text-primary font-semibold pl-4 cursor-pointer" onClick={handleContactUs}>
                        Contact Us
                    </a>
                </div>
            </div>
            <div className="w-full lg:w-1/2">
                <Img fluid={hero.image.fluid} />
            </div>
        </div>

        // <div className="relative overflow-hidden bg-black">
        //     <div className="max-w-screen-xl lg:max-w-full mx-auto">
        //         <div className="relative z-10 bg-black lg:max-w-4xl lg:w-full lg:h-screen">
        //             <svg
        //                 className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-black transform translate-x-1/2"
        //                 fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
        //                 <polygon points="50,0 100,0 50,100 0,100"/>
        //             </svg>
        //             <div className="h-full flex flex-col items-center justify-center text-white">
        //                 <div className="w-full px-4 py-16 lg:px-16">
        //                     <Img fluid={logo.image.fluid} className="w-full"/>
        //                     <H1 className="text-center lg:text-left mt-8">Quick and easy comparables for commercial appraisers.</H1>

        //                     <div className="flex justify-center lg:justify-start mt-8 ">
        //                         <Button onClick={handleSignUp}>Sign Up</Button>
        //                         <Button variant="plain" className="hover:text-white" href="https://app.quickcomp.io/login">Log In</Button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        //         <Img fluid={hero.image.fluid} className="h-56 sm:h-72 md:h-96 lg:h-full w-full lg:w-full"/>
        //     </div>
        // </div>
    );
}
