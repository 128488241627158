import React  from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "./button";

function Header() {
  const { logo } = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: {eq: "logo.png"}) {
         image: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <header className="relative lg:absolute w-full px-4 xl:px-0">
      <div className="flex flex-wrap items-center justify-center md:justify-between max-w-6xl py-4 mx-auto md:py-8">
        <Link to="/">
          <Img fluid={logo.image.fluid} className="w-64" imgStyle={{objectFit: "contain"}}/>
        </Link>
        <div className="hidden md:block">
          <Button href="https://app.quickcomp.io/signup" small={true}>
              Sign Up
          </Button>
          <a href="https://app.quickcomp.io/login" className="hover:text-primary font-semibold pl-4">
            Log In
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
