import React, {cloneElement} from "react"
import PropTypes from "prop-types"

function Button({children, invert, small, /*variant, size, */className, element, disabled, ...props}) {

    // const variantClasses = disabled ? "bg-white border border-gray-light text-gray cursor-default" :
    //     variant === "outlined" ? "bg-white hover:bg-primary text-primary hover:text-white border-2 border-primary cursor-pointer" :
    //     variant === "plain" ? "text-primary border-0" : "bg-primary hover:bg-white text-black hover:text-primary border-2 border-primary cursor-pointer";
    //
    // const sizeClasses = size === "xs" ? "py-1 px-2" :
    //     size === "sm" ? "py-2 px-3" : "py-3 px-4";
    const sizeClasses = small === true ? "py-2 px-3" : "py-4 px-6"
    const colorClasses = disabled ? "bg-white text-gray-light border-gray-light" :
        invert ? "bg-white border-white hover:bg-transparent cursor-pointer" :"bg-primary border-primary hover:bg-white text-black cursor-pointer";
    return (
        <>
            {cloneElement(element, {
                // className: `${variantClasses} ${sizeClasses} font-bold text-base text-center border-solid rounded inline-block ${className}`,
                className: `${colorClasses} ${sizeClasses} border-2 font-bold text-base text-center border-solid rounded inline-flex items-center ${className}`,
                disabled,
                ...props
            }, children)}
        </>
    )
}

Button.propTypes = {
    // variant: PropTypes.string,
    small: PropTypes.bool,
    invert: PropTypes.bool,
    className: PropTypes.string,
    element: PropTypes.node,
    disabled: PropTypes.bool,
    children: PropTypes.any
};

Button.defaultProps = {
    element: <a/>,
    disabled: false
}
export default Button;
