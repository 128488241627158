import React  from 'react';
import {Element} from "react-scroll";
import PropTypes from "prop-types";
import {H1, H2} from "./typography";
import {graphql, useStaticQuery} from "gatsby";
import Button from "./button";
import Chevron from "../images/chevron.svg";

function PlanCard({plan, isLast}) {

    return (
        // <div className="rounded overflow-hidden shadow-lg flex flex-col w-full md:w-256 m-4 items-center" >
        <div className={`flex flex-col w-full md:w-256 px-0 md:px-8 items-center border-solid border-gray-light ${isLast?"border-0":"border-b lg:border-r lg:border-b-0"}`} >
            <div className="w-full flex lg:flex-col justify-center" >
                <div className="w-1/2 lg:w-full py-4">
                    <div className="sm:flex flex-row-reverse items-center">
                        {plan.regular && <span className="uppercase text-white font-bold bg-red px-2 rounded">On Sale</span>}
                        <H2 className="text-black flex-grow">{plan.name}</H2>
                    </div>
                    <div className="flex flex-wrap sm:flex-no-wrap items-baseline mt-8 mb-2">
                        {plan.regular &&
                            <p className="text-2xl text-gray-light font-thin line-through self-center pr-2 w-full sm:w-auto pb-2 sm:pb-0">
                                {plan.regular.price}
                            </p>
                        }
                        <p className="text-5xl font-bold text-primary uppercase leading-6">
                            {plan.price}
                        </p>
                        <p className={`text-xs font-hairline ${plan.amount>0?"visible":"invisible"}`}>
                            CAD
                        </p>
                    </div>
                    <p className="text-black font-thin">
                        {plan.period}
                    </p>
                </div>
                {/*<p className="font-bold mt-4">*/}
                {/*    Includes:*/}
                {/*</p>*/}
                <ul className="list-disc ml-4 mt-4 font-light w-1/2 lg:w-full text-base mg:text-lg lg:text-xl">
                    {plan.limits.map((d) => <li key={d.key} className="py-1">{d.text}</li>)}
                </ul>

            </div>
        </div>
    )
}
PlanCard.propTypes = {
    plan: PropTypes.object,
    isLast: PropTypes.bool,
    chosen: PropTypes.bool,
    onChoose: PropTypes.func
}
export default function Pricing() {
    const { plans } = useStaticQuery(graphql`
        query PricingQuery {
            plans: allPlans {
              edges {
                node {
                  name,
                  price,
                  amount,
                  period,
                  limits {
                    text
                    key
                  }
                }
              }
            }
        }
    `);
    return (
        <Element name="pricing">
            <section className="max-w-6xl mx-auto px-4 xl:px-0 py-8 min-h-screen flex flex-col justify-center mt-8 lg:mt-0">
                <H1 className="text-center lg:text-left">Plans that are right for you.</H1>
                <p className="font-hairline text-base md:text-lg lg:text-xl text-center lg:text-left mt-4">With multiple plans to choose from it&apos;s easy to scale up and down to fit the needs of your business. All plans are month to month with no contracts and you can cancel or change your plan any time.</p>
                <div className="flex flex-wrap lg:flex-no-wrap justify-around mt-16 mb-8">
                    {plans.edges.map(e => e.node).filter(n => !!n.name).map((p,i) => <PlanCard key={p.name} plan={p} isLast={i===(plans.edges.length-2)}/>)}
                </div>
                <div className="text-center">
                    <Button href="https://app.quickcomp.io/signup">
                        Get Started <Chevron className="ml-2 h-4"/>
                    </Button>
                </div>
            </section>
        </Element>
    );
}
